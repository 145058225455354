import React from "react";
import { AuthProvider } from "../../../components/Auth/auth";
import SimpleSpring from "../../../components/Transitions/transitions";
import styles from "../../../styles/Signin.module.scss";
import SignUpHeader from "../../../components/PageComponents/Signup/internal/SignUpHeader";
import LoginLayout from "../../../components/LoginLayout";
import ConnectBidddy from "../../../components/Onboarding/ConnectBidddy";

const ConnectPage = ({ location }) => (
  <SimpleSpring location={location}>
    <SignUpHeader title="Connect to Instagram" />
    <AuthProvider>
      <LoginLayout hideHeader title="Bidddy - Connect Bidddy and Instagram">
        <div className={styles.loginContainer}>
          <ConnectBidddy />
        </div>
      </LoginLayout>
    </AuthProvider>
  </SimpleSpring>
);

export default ConnectPage;
